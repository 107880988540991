<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-form-group label="Status Kelengkapan Pelamar" label-cols-md="3">
            <b-form-select :options="status"></b-form-select>
          </b-form-group>

          <b-form-group label="Keterangan" label-cols-md="3">
            <b-form-textarea rows="3"></b-form-textarea>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "inputTabProfil",

  data() {
    return {
      status: [
        { value: "0", text: "Lolos" },
        { value: "1", text: "Tidak Lolos" },
      ],
    };
  },
};
</script>
