<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Per Halaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          label="Cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Ketik disini untuk mencari ..."
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >Hapus</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="dataRiwayat"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item" )>
            {{ item.index + 1 }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "inputTabProfil",
  props: ["dataRiwayat"],
  data() {
    return {
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "tingkatPendidikan",
          label: "Tingkat Pendidikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaSekolah",
          label: "Nama Sekolah / Universitas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tahunLulus",
          label: "Tahun Lulus",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jurusan",
          label: "Jurusan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
