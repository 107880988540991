<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-form-group label="Status Kelengkapan Pelamar" label-cols-md="3">
            <b-form-select
              v-model="statusPostLoker"
              :options="status"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="" label-cols-md="3">
            <b-button variant="primary" v-b-modal.modal-konfirmasi-input>
              Simpan
            </b-button>
          </b-form-group>

          <!-- <b-form-group label="Keterangan" label-cols-md="3">
            <b-form-textarea rows="3"></b-form-textarea>
          </b-form-group> -->
        </b-form>
      </b-col>
    </b-row>
    <b-modal
      id="modal-konfirmasi-input"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <p>Apakah pilihan Anda sudah sesuai?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="batal"> Tidak </b-button>
        <b-button variant="primary" :disabled="busy" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  name: "detailTabStatus",
  props: ["data"],
  data() {
    return {
      status: [
        { value: 2, text: "Lolos" },
        { value: 0, text: "Tidak Lolos" },
      ],
      statusPostLoker: "",
      busy: false,
      button: "Ya",
    };
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      let datanya = {};
      datanya.id = vm.data.postLokerId;
      datanya.statusPostLoker = vm.statusPostLoker;
      axios
        .post(ipBackend + "postLoker/update", datanya)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Ya";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN PELAMAR KE PROSES TAHAPAN",
              showing: true,
            });
            this.$bvModal.hide("modal-detail");
            this.$bvModal.hide("modal-konfirmasi-input");
          } else {
            vm.button = "Ya";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Ya";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    batal() {
      this.$bvModal.hide("modal-konfirmasi-input");
    },
  },
};
</script>
