<template>
  <div>
    <b-form>
      <b-form-group label="Nama Lengkap" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('namaPelamar')"
          v-model="$v.dataProfil.namaPelamar.$model"
          @change="ngirim()"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tanggal Masuk Lamaran" label-cols-md="3">
        <date-picker
          style="width: 100%"
          v-model="dataProfil.tanggalMasukLamaran"
          @change="ngirim()"
          disabled
          :placeholder="dataProfil.tglMasuk"
        ></date-picker>
      </b-form-group>

      <b-form-group label="No. KTP" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('nomorKTPPelamar')"
          v-model="$v.dataProfil.nomorKTPPelamar.$model"
          @change="ngirim()"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group label="Divisi Yang Dilamar" label-cols-md="3">
        <multiselect
          :disabled="false"
          :options="divisi"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          placeholder="-- Pilih Divisi --"
        ></multiselect>
      </b-form-group> -->

      <b-form-group label="Posisi Yang Dilamar" label-cols-md="3">
        <b-form-input
          type="text"
          v-model="data.posisiDilamar"
          @change="ngirim()"
          readonly
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tinggi Badan" label-cols-md="3">
        <b-form-input
          type="number"
          v-model="dataProfil.tinggiBadanPelamar"
          @change="ngirim()"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Berat Badan" label-cols-md="3">
        <b-form-input
          type="number"
          v-model="dataProfil.beratBadanPelamar"
          @change="ngirim()"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Agama" label-cols-md="3">
        <b-form-select
          v-model="dataProfil.agamaPelamar"
          :options="agama"
          @change="ngirim()"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Kebangsaan" label-cols-md="3">
        <b-form-select
          v-model="dataProfil.kebangsaanPelamar"
          :options="kebangsaan"
          @change="ngirim()"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Jenis Kelamin" label-cols-md="3">
        <b-form-select
          v-model="dataProfil.jenisKelaminPelamar"
          :options="jenisKelamin"
          @change="ngirim()"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Status Pernikahan" label-cols-md="3">
        <b-form-select
          v-model="dataProfil.statusPelamar"
          :options="statusPerkawinan"
          @change="ngirim()"
        ></b-form-select>
      </b-form-group>

      <!-- <b-form-group label="Pendidikan Terakhir" label-cols-md="3">
        <b-form-select v-model="dataProfil.kebangsaanPelamar" :options="pendidikanTerakhir"></b-form-select>
      </b-form-group> -->

      <b-form-group label="Tempat Lahir" label-cols-md="3">
        <b-form-input
          type="text"
          v-model="dataProfil.tempatLahirPelamar"
          @change="ngirim()"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tanggal Lahir" label-cols-md="3">
        <date-picker
          style="width: 100%"
          v-model="dataProfil.tanggalLahirPengirim"
          @change="ngirim()"
          :placeholder="dataProfil.tglLahir"
          disabled
        ></date-picker>
      </b-form-group>

      <b-form-group label="No. Telepon/Hp" label-cols-md="3">
        <b-form-input
          type="number"
          :state="checkIfValid('nomorTeleponPengirim')"
          v-model="$v.dataProfil.nomorTeleponPengirim.$model"
          @change="ngirim()"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Email" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('emailPelamar')"
          v-model="$v.dataProfil.emailPelamar.$model"
          @change="ngirim()"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Alamat" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('alamatPelamar')"
          v-model="$v.dataProfil.alamatPelamar.$model"
          @change="ngirim()"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group
        label="Keterangan Lain/Alasan Menolak Lamaran"
        label-cols-md="3"
      >
        <b-form-textarea id="textarea" rows="3" max-rows="6"></b-form-textarea>
      </b-form-group> -->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import moment from "moment";
export default {
  name: "inputTabProfil",
  props: ["dataProfil", "data", "loker", "edit2"],
  components: {
    DatePicker,
    Multiselect,
  },

  data() {
    return {
      // dataProfil: {
      //   namaPelamar: "",
      //   nomorKTPPelamar: "",
      //   tinggiBadanPelamar: "",
      //   posisiLamaran: "",
      //   beratBadanPelamar: "",
      //   agamaPelamar: "",
      //   kebangsaanPelamar: "",
      //   jenisKelaminPelamar: "",
      //   statusPelamar: "",
      //   tempatLahirPelamar: "",
      //   tanggalLahirPelamar: "",
      //   nomorTeleponPengirim: "",
      //   emailPelamar: "",
      //   alamatPelamar: "",
      //   tanggalMasukLamaran: "",
      // },
      kebangsaan: [
        { value: "WNI", text: "WNI" },
        { value: "WNA", text: "WNA" },
      ],
      agama: [
        { value: "Islam", text: "Islam" },
        { value: "Katholik", text: "Katholik" },
        { value: "Protestan", text: "Protestan" },
        { value: "Hindu", text: "Hindu" },
        { value: "Budha", text: "Budha" },
        { value: "Khonghucu", text: "Khonghucu" },
      ],
      jenisKelamin: [
        { value: "Laki-laki", text: "Laki-laki" },
        { value: "Perempuan", text: "Perempuan" },
      ],
      statusPerkawinan: [
        { value: "Menikah", text: "Menikah" },
        { value: "Belum Menikah", text: "Belum Menikah" },
      ],
      pendidikanTerakhir: [
        { value: "SD", text: "SD" },
        { value: "SMP", text: "SMP" },
        { value: "MTs", text: "MTs" },
        { value: "SMA", text: "SMA" },
        { value: "SMK", text: "SMK" },
        { value: "MA", text: "MA" },
        { value: "D-1", text: "D-1" },
        { value: "D-2", text: "D-2" },
        { value: "D-3", text: "D-3" },
        { value: "D-4", text: "D-4" },
        { value: "S-1", text: "S-1" },
        { value: "S-2", text: "S-2" },
        { value: "Profesi", text: "Profesi" },
        { value: "Kejar Paket C", text: "Kerjar Paket C" },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataProfil, null, 4);
    },
    isValid() {
      return !this.$v.dataProfil.$invalid;
    },
    isDirty() {
      return this.$v.dataProfil.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataProfil: {
      namaPelamar: {
        required,
      },
      posisiLamaran: {
        required,
      },
      nomorKTPPelamar: {
        required,
      },
      nomorTeleponPengirim: {
        required,
      },
      alamatPelamar: {
        required,
      },
      emailPelamar: {
        required,
      },
    },
  },
  watch: {
    edit2: function (newVal, oldVal) {
      if (oldVal != newVal) {
        // if (data.kelengkapanLamaranId != null) {
        //   this.data.namaPelamar = this.data.namaPelamar;
        //   this.dataProfil.nomorKTPPelamar = this.data.nomorKTPPelamar;
        //   this.dataProfil.tinggiBadanPelamar = this.data.tinggiBadanPelamar;
        //   this.dataProfil.posisiLamaran = this.data.posisiDilamar;
        //   this.dataProfil.beratBadanPelamar = this.data.beratBadanPelamar;
        //   this.dataProfil.agamaPelamar = this.data.agamaPelamar;
        //   this.dataProfil.kebangsaanPelamar = this.data.kebangsaanPelamar;
        //   this.dataProfil.jenisKelaminPelamar = this.data.jenisKelaminPelamar;
        //   this.dataProfil.statusPelamar = this.data.statusPelamar;
        //   this.dataProfil.tempatLahirPelamar = this.data.tempatLahirPelamar;
        //   this.dataProfil.noHpPelamar = this.data.noHpPelamar;
        //   this.dataProfil.emailPelamar = this.data.emailPelamar;
        //   this.dataProfil.alamatPelamar = this.data.alamatPelamar;
        // }
      }
    },
  },
  mounted() {
    this.dataProfil.tanggalMasukLamaran = moment(new Date())
      .startOf("day")
      .format("YYYY-MM-DD");
    this.dataProfil.tglMasuk = moment(
      this.dataProfil.tanggalMasukLamaran
    ).format("LL");
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataProfil[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {},
    ngirim() {
      let vm = this;
      this.$emit("profilFromChild", vm.dataProfil);
    },
  },
};
</script>
