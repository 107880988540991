<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-form-group label="Tingkat Pendidikan" label-cols-md="3">
            <b-form-select
              :state="checkIfValid('tingkatPendidikan')"
              v-model="$v.dataPendidikan.tingkatPendidikan.$model"
              :options="pendidikanTerakhir"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Nama Sekolah/Universitas" label-cols-md="3">
            <b-form-input
              :state="checkIfValid('namaSekolah')"
              v-model="$v.dataPendidikan.namaSekolah.$model"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Tahun Kelulusan" label-cols-md="3">
            <b-form-input
              :state="checkIfValid('tahunLulus')"
              v-model="$v.dataPendidikan.tahunLulus.$model"
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Jurusan" label-cols-md="3">
            <b-form-input
              v-model="dataPendidikan.jurusan"
              type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <b-button
              @click="addPendidikan"
              :disabled="!isValid"
              variant="primary"
              >Tambahkan</b-button
            >
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-form-group
          label="Per Halaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          label="Cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Ketik disini untuk mencari ..."
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >Hapus</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="listRiwayat"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(actions)="row">
            <b-button
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="'Hapus'"
              @click="deleteItem(row.index)"
              ><CIcon name="cil-trash" /> {{ row.actions }}</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "inputTabProfil",
  props: ["listRiwayat"],
  data() {
    return {
      dataPendidikan: {
        tingkatPendidikan: "",
        tahunLulus: "",
        namaSekolah: "",
        jurusan: "",
      },
      pendidikan: {},
      pendidikanTerakhir: [
        { value: "SD", text: "SD" },
        { value: "SMP", text: "SMP" },
        { value: "MTs", text: "MTs" },
        { value: "SMA", text: "SMA" },
        { value: "SMK", text: "SMK" },
        { value: "MA", text: "MA" },
        { value: "D-1", text: "D-1" },
        { value: "D-2", text: "D-2" },
        { value: "D-3", text: "D-3" },
        { value: "D-4", text: "D-4" },
        { value: "S-1", text: "S-1" },
        { value: "S-2", text: "S-2" },
        { value: "Profesi", text: "Profesi" },
        { value: "Kejar Paket C", text: "Kerjar Paket C" },
      ],

      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "tingkatPendidikan",
          label: "Tingkat Pendidikan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaSekolah",
          label: "Nama Sekolah / Universitas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tahunLulus",
          label: "Tahun Lulus",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jurusan",
          label: "Jurusan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.dataPendidikan, null, 4);
    },
    isValid() {
      return !this.$v.dataPendidikan.$invalid;
    },
    isDirty() {
      return this.$v.dataPendidikan.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPendidikan: {
      tingkatPendidikan: {
        required,
      },
      tahunLulus: {
        required,
      },
      namaSekolah: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataPendidikan[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    reset() {
      let vm = this;
      vm.dataPendidikan.tingkatPendidikan = null;
      vm.dataPendidikan.namaSekolah = "";
      vm.dataPendidikan.tahunLulus = "";
      vm.dataPendidikan.jurusan = "";
      vm.$v.$reset();
    },
    addPendidikan() {
      let vm = this;
      let penampung = {};
      penampung.tingkatPendidikan = vm.dataPendidikan.tingkatPendidikan;
      penampung.namaSekolah = vm.dataPendidikan.namaSekolah;
      penampung.tahunLulus = vm.dataPendidikan.tahunLulus;
      penampung.jurusan = vm.dataPendidikan.jurusan;
      vm.listRiwayat.push(penampung);
      // vm.items.forEach((element, index) => {
      //   vm.items[index].no = index + 1;
      // });
      vm.reset();
      vm.pendidikan.data = vm.listRiwayat;
      vm.$emit("riwayatFromChild", vm.pendidikan);
    },
    deleteItem(index) {
      this.listRiwayat.splice(index, 1);
      this.$emit("riwayatFromChild", this.pendidikan);
    },
  },
};
</script>
