<template>
  <div>
    <b-table-simple striped table-sm>
      <b-tbody>
        <b-tr>
          <b-td style="width: 25%">Nama Lengkap</b-td>
          <b-td style="width: 5%">:</b-td>
          <b-td>{{ dataProfil.namaPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Tanggal Masuk Lamaran</b-td>
          <b-td>:</b-td>
          <b-td v-if="dataProfil.tanggalMasukLamaran">{{
            dataProfil.tglMasuk
          }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>No. KTP</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.nomorKTPPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Posisi Yang Dilamar</b-td>
          <b-td>:</b-td>
          <b-td
            >{{ dataProfil.namaLoker }} - (posisi :
            {{ dataProfil.namaPosisi }})</b-td
          >
        </b-tr>

        <b-tr>
          <b-td>Tinggi Badan</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.tinggiBadanPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Berat Badan</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.beratBadanPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Agama</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.agamaPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Kebangsaan</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.kebangsaanPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Jenis Kelamin</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.jenisKelaminPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Status Pernikahan</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.statusPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Tempat Lahir</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.tempatLahirPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Tanggal Lahir</b-td>
          <b-td>:</b-td>
          <b-td v-if="dataProfil.tanggalLahirPengirim">{{
            dataProfil.tglLahir
          }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>No. Telepon/Hp</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.nomorTeleponPengirim }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Email</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.emailPelamar }}</b-td>
        </b-tr>

        <b-tr>
          <b-td>Alamat</b-td>
          <b-td>:</b-td>
          <b-td>{{ dataProfil.alamatPelamar }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("id");
export default {
  name: "detailTabProfil",
  props: ["dataProfil", "detail"],
  data() {
    return {};
  },
};
</script>
