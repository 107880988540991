<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="kebutuhanPelamar"
          :fields="fields"
          responsive
          small
          bordered
          striped
          hover
        >
          <template #cell(file1)="item">
            <b-button
              variant="primary"
              class="mr-1"
              @click="
                selectFile(item.index),
                  (kebutuhanPelamarId = item.item.kebutuhanPelamarId)
              "
              >Upload File</b-button
            >
            <b-button
              style="margin-left: 10px"
              v-if="item.item.statusKebutuhan != 0"
              variant="dark"
              @click="view(item.item)"
              >Lihat File</b-button
            >
          </template>
          <template #cell(statusKebutuhan)="item">
            <b-form-checkbox
              v-model="item.item.statusKebutuhan"
              disabled
              value="1"
              unchecked-value="0"
            >
            </b-form-checkbox>
          </template>
        </b-table>
        <div class="input-files">
          <input
            class="inputan"
            type="file"
            id="file1"
            ref="file1"
            @input="handleFile()"
          />
        </div>
        <!-- <b-table-simple bordered striped>
          <b-thead>
            <b-tr>
              <b-th>Checklist</b-th>
              <b-th>Lampiran</b-th>
              <b-th>Upload File</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td style="vertical-align: middle">
                <center>
                  <b-form-checkbox
                    v-model="data.pasFoto4x6"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                    @change="ngirim()"
                  >
                  </b-form-checkbox>
                </center>
              </b-td>
              <b-td style="vertical-align: middle"
                >Pas Foto 4x6 (2 Lembar)</b-td
              >
              <b-td>
                <b-form-file plain type="file"></b-form-file>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  name: "inputTabProfil",
  props: ["kebutuhanPelamar", "data"],
  data() {
    return {
      // data: {
      //   pasFoto4x6: 0,
      // },
      indexUpload: "",
      kebutuhanPelamarId: "",
      postLokerId: "",
      file1: "",
      loading: false,
      fields: [
        {
          key: "namaKebutuhan",
          label: "Nama Lampiran",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "file1",
          label: "Upload File",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusKebutuhan",
          label: "Checklist",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
    };
  },
  methods: {
    handleFile() {
      let vm = this;
      vm.file1 = vm.$refs.file1.files[0];
      vm.kirimFile(vm.indexUpload);
    },
    selectFile(y) {
      let fileInputElement = this.$refs.file1;
      fileInputElement.click();
      this.indexUpload = y;
    },
    view(x) {
      if (!x.file1) {
        window.open(`${ipBackend}asset/pdf/${x.fileKebutuhan}`);
      } else {
        window.open(`${ipBackend}asset/pdf/${x.file1}`);
      }
    },
    kirimFile(y) {
      let vm = this;
      let formData = new FormData();
      formData.append("kebutuhanPelamarId", vm.kebutuhanPelamarId);
      formData.append("file1", vm.file1);
      axios
        .post(ipBackend + "kelengkapanLamaran/updateFile", formData)
        .then((res) => {
          console.log(res);
          if (res.data.message == "sukses") {
            vm.loading = false;
            vm.kebutuhanPelamar[y].statusKebutuhan = 1;
            vm.kebutuhanPelamar[y].file1 = res.data.data;
          } else {
            vm.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.inputan {
  visibility: hidden;
}
</style>
