<template>
  <div>
    <b-table-simple bordered striped>
      <b-thead>
        <b-tr>
          <b-th>Syarat Dokumen Administrasi</b-th>
          <b-th>File</b-th>
          <b-th>Checklist</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item in kebutuhanPelamar" :key="item.kebutuhanPelamarId">
          <b-td>{{ item.namaKebutuhan }}</b-td>
          <b-td>
            <b-button
              variant="success"
              size="sm"
              v-if="item.fileKebutuhan != null"
              @click="view(item.fileKebutuhan)"
              >Lihat File</b-button
            >

            <b-button v-else variant="danger" size="sm"
              >Tidak Ada File</b-button
            >
          </b-td>

          <b-td>
            <b-form-checkbox
              v-model="item.statusKebutuhan"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >
            </b-form-checkbox>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import { ipBackend } from "@/ipBackend";
export default {
  name: "detailTabLampiran",
  props: ["kebutuhanPelamar"],
  data() {
    return {};
  },
  methods: {
    view(x) {
      window.open(`${ipBackend}asset/pdf/${x}`);
    },
  },
};
</script>
