<template>
  <div>
    <b-modal
      id="modal-detail"
      size="lg"
      centered
      title="Detail Pelamar"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-tabs content-class="mt-3" align="center">
        <b-tab title="Profil Pelamar">
          <detail-tab-profil :dataProfil="dataProfil" />
        </b-tab>
        <b-tab title="Riwayat Pendidikan">
          <detail-tab-pendidikan :dataRiwayat="dataRiwayat" />
        </b-tab>
        <b-tab title="Pengalaman Kerja">
          <detail-tab-kerja :dataPengalaman="dataPengalaman" />
        </b-tab>
        <b-tab title="Lampiran">
          <detail-tab-lampiran :kebutuhanPelamar="kebutuhanPelamar" />
        </b-tab>
        <b-tab title="Status">
          <detail-tab-status
            :data="data"
            @alertFromChild="triggerAlert($event)"
          />
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import detailTabProfil from "../../../component/hcms/kelengkapanPelamar/detailTabProfil.vue";
import detailTabPendidikan from "../../../component/hcms/kelengkapanPelamar/detailTabPendidikan.vue";
import detailTabKerja from "../../../component/hcms/kelengkapanPelamar/detailTabKerja.vue";
import detailTabLampiran from "../../../component/hcms/kelengkapanPelamar/detailTabLampiran.vue";
import detailTabStatus from "../../../component/hcms/kelengkapanPelamar/detailTabStatus.vue";
import moment from "moment";
moment.locale("id");
export default {
  name: "modalDetail",
  props: ["data", "detail"],
  components: {
    detailTabProfil,
    detailTabPendidikan,
    detailTabKerja,
    detailTabLampiran,
    detailTabStatus,
  },
  data() {
    return {
      dataRiwayat: [],
      dataPengalaman: [],
      dataProfil: [],
      kebutuhanPelamar: [],
    };
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.getDetail();
      }
    },
  },
  methods: {
    async getDetail() {
      let vm = this;
      let id = vm.data.postLokerId;
      let status = vm.data.statusPostLoker;
      let listDetail = await axios.get(
        ipBackend + "postLoker/detailListByStatus/" + id
      );
      console.log(listDetail.data.data, "hi");
      vm.dataRiwayat = listDetail.data.data[0].riwayatPendidikan;
      vm.dataPengalaman = listDetail.data.data[0].pengalamanKerja;
      vm.dataProfil = listDetail.data.data[0];
      vm.kebutuhanPelamar = listDetail.data.data[0].kebutuhanPelamar;
      vm.dataProfil.tglLahir = moment(
        vm.dataProfil.tanggalLahirPengirim
      ).format("LL");
      vm.dataProfil.tglMasuk = moment(vm.dataProfil.tanggalMasukLamaran).format(
        "LL"
      );
    },
    triggerAlert(event) {
      let vm = this;
      vm.$emit("alertFromChild", {
        variant: event.variant,
        msg: event.msg,
        showing: event.showing,
      });
    },
  },
};
</script>
