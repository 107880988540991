<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Kelengkapan Pelamar"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-tabs content-class="mt-3" align="center">
        <b-tab title="Profil Pelamar">
          <edit-tab-profil
            :dataProfil="dataProfil"
            :data="data"
            :loker="loker"
            :edit2="edit2"
            @profilFromChild="fromProfil($event)"
          />
        </b-tab>
        <b-tab title="Riwayat Pendidikan">
          <edit-tab-pendidikan
            :listRiwayat="listRiwayat"
            :edit2="edit2"
            @riwayatFromChild="fromRiwayat($event)"
          />
        </b-tab>
        <b-tab title="Pengalaman Kerja">
          <edit-tab-kerja
            :listPengalaman="listPengalaman"
            :edit2="edit2"
            @pengalamanFromChild="fromPengalaman($event)"
          />
        </b-tab>
        <b-tab title="Lampiran">
          <edit-tab-lampiran
            :kebutuhanPelamar="kebutuhanPelamar"
            :data="data"
          />
        </b-tab>
        <!-- <b-tab title="Status">
          <edit-tab-status />
        </b-tab> -->
      </b-tabs>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Batal
        </b-button>
        <b-button
          v-if="dataProfil != '' && dataRiwayat != '' && dataPengalaman != ''"
          :disabled="bussy"
          variant="primary"
          v-b-modal.modal-konfirmasi-input
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-konfirmasi-input"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <p>Apakah data Anda sudah sesuai?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="batal"> Batal </b-button>
        <b-button variant="primary" :disabled="bussy" @click="create">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import editTabProfil from "../../../component/hcms/kelengkapanPelamar/editTabProfil.vue";
import editTabPendidikan from "../../../component/hcms/kelengkapanPelamar/editTabPendidikan.vue";
import editTabKerja from "../../../component/hcms/kelengkapanPelamar/editTabKerja.vue";
import editTabLampiran from "../../../component/hcms/kelengkapanPelamar/editTabLampiran.vue";
import editTabStatus from "../../../component/hcms/kelengkapanPelamar/editTabStatus.vue";
import moment from "moment";
moment.locale("id");
export default {
  name: "modalInput",
  props: ["data", "loker", "edit"],
  components: {
    editTabProfil,
    editTabPendidikan,
    editTabKerja,
    editTabLampiran,
    editTabStatus,
  },

  data() {
    return {
      variant: "",
      msg: "",
      button: "Simpan",
      bussy: false,
      dataProfil: {},
      dataRiwayat: "",
      dataPengalaman: "",
      dataLampiran: "",
      listRiwayat: [],
      listPengalaman: [],
      penampung: [],
      kebutuhanPelamar: [],
      edit2: false,
    };
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.dataProfil = {};
        if (
          this.data.statusKelengkapan == "" ||
          this.data.statusKelengkapan == null
        ) {
          this.getKebutuhan();
          this.dataProfil.nomorKTPPelamar = this.data.nomorKTPPengirim;
          this.dataProfil.namaPelamar = this.data.namaPengirim;
          this.dataProfil.alamatPelamar = this.data.alamatPengirim;
          this.dataProfil.emailPelamar = this.data.emailPengirim;
          this.dataProfil.nomorTeleponPengirim = this.data.nomorTeleponPengirim;
          this.dataProfil.tanggalLahirPengirim = this.data.tanggalLahirPengirim;
          if (this.data.tanggalLahirPengirim) {
            this.dataProfil.tglLahir = moment(
              this.data.tanggalLahirPengirim
            ).format("LL");
          }
          this.dataProfil.tanggalMasukLamaran = moment(new Date())
            .startOf("day")
            .format("YYYY-MM-DD");
          this.dataProfil.tglMasuk = moment(
            this.dataProfil.tanggalMasukLamaran
          ).format("LL");
        } else {
          this.getKebutuhan();
          this.getDetail();
        }
      }
    },
  },
  methods: {
    fromProfil(data) {
      this.dataProfil = data;
    },
    fromRiwayat(data) {
      this.dataRiwayat = data.data;
    },
    fromPengalaman(data) {
      this.dataPengalaman = data.data;
    },
    fromLampiran(data) {
      this.dataLampiran = data;
    },
    async getKebutuhan() {
      let vm = this;
      let id = this.data.postLokerId;
      let listKebutuhan = await axios.get(
        ipBackend + "postLoker/listKebutuhanByLokerId/" + id
      );

      vm.kebutuhanPelamar = listKebutuhan.data.data.map((item) => {
        return {
          ...item,
          file1: "",
        };
      });
    },
    getDetail() {
      let vm = this;
      let id = vm.data.postLokerId;
      let status = vm.data.statusPostLoker;
      vm.dataProfil = {};
      axios
        .get(ipBackend + "postLoker/detailListByStatus/" + id)
        .then((res) => {
          vm.listRiwayat = res.data.data[0].riwayatPendidikan;
          vm.listPengalaman = res.data.data[0].pengalamanKerja;
          vm.dataRiwayat = res.data.data[0].riwayatPendidikan;
          vm.dataPengalaman = res.data.data[0].pengalamanKerja;
          vm.kebutuhanPelamar = res.data.data[0].kebutuhanPelamar;
          vm.dataProfil = res.data.data[0];
          if (this.data.tanggalLahirPengirim) {
            this.dataProfil.tglLahir = moment(
              this.data.tanggalLahirPengirim
            ).format("LL");
          }
          vm.dataProfil.tglMasuk = moment(
            vm.dataProfil.tanggalMasukLamaran
          ).format("LL");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    create() {
      let vm = this;
      let x = {
        namaPelamar: vm.dataProfil.namaPelamar,
        nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
        posisiLamaran: vm.data.posisiDilamar,
        tinggiBadanPelamar: vm.dataProfil.tinggiBadanPelamar,
        beratBadanPelamar: vm.dataProfil.beratBadanPelamar,
        agamaPelamar: vm.dataProfil.agamaPelamar,
        kebangsaanPelamar: vm.dataProfil.kebangsaanPelamar,
        jenisKelaminPelamar: vm.dataProfil.jenisKelaminPelamar,
        statusPelamar: vm.dataProfil.statusPelamar,
        tempatLahirPelamar: vm.dataProfil.tempatLahirPelamar,
        tanggalLahirPelamar: vm.dataProfil.tanggalLahirPengirim,
        noHpPelamar: vm.dataProfil.nomorTeleponPengirim,
        emailPelamar: vm.dataProfil.emailPelamar,
        alamatPelamar: vm.dataProfil.alamatPelamar,
        tanggalMasukLamaran: vm.dataProfil.tanggalMasukLamaran,
        nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
        postLokerId: vm.data.postLokerId,
        statusKelengkapan: 1,
        bulkPengalamanKerja: vm.dataPengalaman,
        bulkRiwayatPendidikan: vm.dataRiwayat,
      };
      console.log(x, "inix", this.dataProfil.tanggalLahirPengirim);
      if (vm.data.statusKelengkapan == null) {
        axios
          .post(ipBackend + "kelengkapanLamaran/register", x)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.bussy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGEDIT DATA KELENGKAPAN",
                showing: true,
              });
              vm.$bvModal.hide("modal-edit");
              vm.$bvModal.hide("modal-konfirmasi-input");
            } else {
              vm.bussy = false;
              vm.$bvModal.hide("modal-edit");
              vm.tabIndex = 0;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.tabIndex = 0;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      } else {
        axios
          .post(ipBackend + "kelengkapanLamaran/updateAll", {
            id: vm.data.kelengkapanLamaranId,
            namaPelamar: vm.dataProfil.namaPelamar,
            nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
            posisiLamaran: vm.data.posisiDilamar,
            tinggiBadanPelamar: vm.dataProfil.tinggiBadanPelamar,
            beratBadanPelamar: vm.dataProfil.beratBadanPelamar,
            agamaPelamar: vm.dataProfil.agamaPelamar,
            kebangsaanPelamar: vm.dataProfil.kebangsaanPelamar,
            jenisKelaminPelamar: vm.dataProfil.jenisKelaminPelamar,
            statusPelamar: vm.dataProfil.statusPelamar,
            tempatLahirPelamar: vm.dataProfil.tempatLahirPelamar,
            tanggalLahirPelamar: vm.dataProfil.tanggalLahirPengirim,
            noHpPelamar: vm.dataProfil.noHpPelamar,
            emailPelamar: vm.dataProfil.emailPelamar,
            alamatPelamar: vm.dataProfil.alamatPelamar,
            tanggalMasukLamaran: vm.dataProfil.tanggalMasukLamaran,
            nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
            postLokerId: vm.data.postLokerId,
            statusKelengkapan: 1,
            bulkPengalamanKerja: vm.dataPengalaman,
            bulkRiwayatPendidikan: vm.dataRiwayat,
          })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.bussy = false;
              vm.$bvModal.hide("modal-edit");
              vm.$bvModal.hide("modal-konfirmasi-input");
            } else {
              vm.bussy = false;
              vm.$bvModal.hide("modal-edit");
              vm.tabIndex = 0;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.tabIndex = 0;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    batal() {
      this.$bvModal.hide("modal-konfirmasi-input");
    },
  },
};
</script>
